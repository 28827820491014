<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h5 primary--text">สินค้าเพิ่มตัง</div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มรายการ</v-btn
      >
    </v-toolbar>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >

      <template v-slot:[`item.category`]="{ item }">
        {{item.category == "0" ? "หลัก" : ""}}
        {{item.category == "1" ? "ทั่วไป" : ""}}
      </template>
      <template v-slot:[`item.product_type`]="{ item }">
        {{item.product_type == "0" ? "ครั้งเดียว" : ""}}
        {{item.product_type == "1" ? "รายเดือน" : ""}}
        {{item.product_type == "2" ? "รายปี" : ""}}
      </template>
      
      <template v-slot:[`item.price`]="{ item }">
        {{item.price | numeral('0,0.00')}}
      </template>
      <template v-slot:[`item.bills`]="{ item }">
        {{item.is_unlimit ? 'Unlimit' : item.bills }}
      </template>
      <!-- <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship-document small v-model="item.is_available" :id="item._id" :statusId="item.is_available" @success="getData(true)"  />
      </template>-->

      <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship
          v-model="item.is_available"
          path="/backend/product/changed_product_status"
          :params="{ _id: item._id}"
          @success="$alertSuccess(), getData()"
        />
      </template> 

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as moment from "moment";

export default {
  components: {

  },
  data: () => ({
    _id: " ",
    loading: true,
    pageCount: 0,
    list: [],
    headers: [
      { text: "การจัดเรียง", value: "index" },
      { text: "รหัสสินค้า", value: "product_id" },
      { text: "ชื่อสินค้า", value: "name", filterable: false },
      { text: "หมวดหมู่", value: "category" },
      { text: "แบบ", value: "product_type", filterable: false },
      { text: "ราคา", value: "price", filterable: false },
      { text: "Max Bill", value: "bills", filterable: false },
      { text: "สถานะ", value: "is_available", sortable: false, filterable: false, align :'center' },
      { text: "แก้ไข", value: "actions", sortable: false, filterable: false },
    ],
    status: [
      { text: "สถานะ", value: "" },
      { text: "รอดำเนินการ", value: 0 },
      { text: "เปิดบิลแล้ว", value: 1 },
      { text: "ยกเลิก", value: 2 },
    ],
    filter: {
      branch_id: 0,
      status: "",
      item_per_page: 20,
      page: 1,
      start_with: "",
      start_date: moment().add(-48, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    },
  }),
  created() {
    this.getData();
  },

  methods: {
    add() {
      this.$router.push({ name: "backoffice-product-create" });
    },
    edit(item) {
      this.$router.push({ name: "backoffice-product-update", params: { id: item._id }});
    },
    async remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ _id: item._id}, this.$privateKey, {noTimestamp: true}),
            };
            await this.$axios.post(`${this.$baseUrl}/backend/product/delete_product`, body)
              .then((res) => {
                this.$alertSuccess({ title : res.status });
                this.getData();
              })
              .catch((err) => {
                console.log("err", err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log("remove()");
          }
        }
      );
    },

    async getData(reload = false) {
      if(reload){
        this.onChangeitemsPerPage(this.filter.item_per_page)
      }
      this.filter.end_date = moment(this.filter.end_date).set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {noTimestamp: true})
      };
      await this.$axios.post(`${this.$baseUrl}/backend/product/get_product`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log("err", err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page){
      this.filter.page = page
      this.getData()
    },
    onChangeitemsPerPage(number){
      this.filter.item_per_page = number
      this.onChangePage(1)
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>